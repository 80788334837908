<template>
  <div>
    <el-form
      ref="commonForm"
      :model="commonForm"
      :rules="rules"
      class="commonForm"
      label-width="220px"
    >
      <el-form-item :label="autoPrefix('21ca9e3', 'lab')" prop="userName">
        <el-popover
          ref="popover"
          placement="top-start"
          trigger="focus"
          :content="autoPrefix('73101e4')"
          popper-class="userNamePopover"
        />
        <el-input
          v-model="commonForm.userName"
          v-popover:popover
          :placeholder="autoPrefix('d25ba30')"
        />
      </el-form-item>
      <el-form-item v-if="abroad" :label="autoPrefix('5de8183', 'lab')" prop="email">
        <el-input
          v-model="commonForm.email"
          :placeholder="autoPrefix('dd4858c')"
        />
      </el-form-item>
      <el-form-item v-else :label="autoPrefix('e4ef183', 'lab')" prop="phone">
        <el-input
          v-model="commonForm.phone"
          :placeholder="autoPrefix('6171f9a')"
        />
      </el-form-item>
      <el-form-item
        :label="autoPrefix('d34532d', 'lab')"
        prop="graphical"
        class="graphical"
      >
        <el-input
          v-model="commonForm.graphical"
          :placeholder="autoPrefix('76ef44c')"
        />
        <GraphicalCode ref="GraphicalCode" class="uiImg" />
      </el-form-item>
      <el-form-item v-if="abroad" :label="autoPrefix('f96d75d', 'lab')" prop="short" class="short">
        <el-input
          v-model="commonForm.short"
          :placeholder="autoPrefix('3c12dca')"
        />
        <div class="uiText" @click="handleShortTips">
          {{ showShortTips }}
        </div>
      </el-form-item>
      <el-form-item v-else :label="autoPrefix('b0a1a85', 'lab')" prop="short" class="short">
        <el-input
          v-model="commonForm.short"
          :placeholder="autoPrefix('46259e0')"
        />
        <div class="uiText" @click="handleShortTips">
          {{ showShortTips }}
        </div>
      </el-form-item>

      <el-form-item v-if="abroad" :label="autoPrefix('e4ef183', 'lab')" prop="phone">
        <el-popover
          ref="popoverPhone"
          placement="top-start"
          trigger="focus"
          :content="autoPrefix('af0d83f')"
          popper-class="userNamePopover"
        />
        <el-input
          v-model="commonForm.phone"
          v-popover:popoverPhone
          :placeholder="autoPrefix('6171f9a')"
        />
      </el-form-item>
      <el-form-item v-else :label="autoPrefix('5de8183', 'lab')" prop="email">
        <el-input
          v-model="commonForm.email"
          :placeholder="autoPrefix('dd4858c')"
        />
      </el-form-item>

      <el-form-item :label="autoPrefix('ef62f45', 'lab')" prop="password">
        <el-input
          v-model="commonForm.password"
          show-password
          :placeholder="autoPrefix('45308b0')"
        />
      </el-form-item>
      <el-form-item
        :label="autoPrefix('9fa64ba', 'lab')"
        prop="confirmPassword"
      >
        <el-input
          v-model="commonForm.confirmPassword"
          show-password
          :placeholder="autoPrefix('b937d0f')"
        />
      </el-form-item>
      <el-form-item label="" prop="agreement" class="agreement">
        <!-- <el-form-item label="" class="agreement"> -->
        <el-checkbox v-model="commonForm.agreement">
          {{ autoPrefix('7c91e4d') }}
        </el-checkbox>
        <span class="uiText" @click="showAgreement">{{
          autoPrefix('《涉外法律服务大数据平台用户注册协议》')
        }}</span>
      </el-form-item>

      <el-form-item v-if="registerType === 'PERSON'">
        <el-button
          type="primary"
          class="loginButton"
          @click="submitForm('commonForm')"
        >
          {{ autoPrefix('4c50f3d') }}
        </el-button>
      </el-form-item>

      <el-form-item v-else class="commonButtonContainer">
        <el-button class="commonButton" @click="lastStep">{{
          autoPrefix('f51f987')
        }}</el-button>
        <el-button
          type="primary"
          class="commonButton"
          @click="submitForm('commonForm')"
        >
          {{ autoPrefix('127e973') }}
        </el-button>
      </el-form-item>
    </el-form>
    <Agreement :visible.sync="visible" />
  </div>
</template>

<script>
import { base64Password } from '@/utils/helper'
import GraphicalCode from '@/components/GraphicalCode'
import validator from '@/utils/validation'
import Agreement from '@/components/RegisterAgreement'
import API from '@/api'
import { log } from '@/utils/helper'
// const i18nPrefix = {
//   label: 'account.register.commonForm.lable.',
//   pla: 'account.register.commonForm.pla.',
//   com: 'account.register.com.',
// }
export default {
  name: 'CommonForm',
  components: {
    GraphicalCode,
    Agreement,
  },
  props: {
    registerType: {
      type: String,
      default: 'PERSON',
    },
  },

  data() {
    return {
      userNameFocus: false,
      visible: false,
      shortTips: '',
      commonForm: {
        userName: '',
        phone: '',
        graphical: '',
        short: '',
        email: '',
        password: '',
        confirmPassword: '',
        agreement: '',
      },
    }
  },
  computed: {
    rules() {
      return {
        userName: [
          { required: true, message: this.autoPrefix('d25ba30') },
          {
            validator: validator.userName,
          },
        ],
        phone: [
          { required: true, message: this.autoPrefix('6171f9a', 'pla') },
          { validator: validator.phone_zh },
        ],
        graphical: [
          { required: true, message: this.autoPrefix('46259e0', 'pla') },
          { validator: validator.graphical },
        ],
        short: [
          { validator: validator.short },
        ],
        email: [
          { required: true, message: this.autoPrefix('dd4858c', 'pla') },
          { validator: validator.email },
        ],
        password: [
          { required: true, message: this.autoPrefix('45308b0', 'pla') },
          { validator: validator.password },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.autoPrefix('702d5d6', 'pla'),
          },
          { validator: validator.password },
          {
            validator: (rule, value, callback) => {
              if (value !== this.commonForm.password) {
                callback(new Error(this.autoPrefix('8baf675')))
              }
              callback()
            },
          },
        ],
        agreement: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.autoPrefix('agreement', 'pla')))
              }
              callback()
            },
          },
          // { required: true, message: this.autoPrefix('agreement', 'pla') },
        ],
      }
    },
    abroad() {
      return this.$route.query.abroad === 'abroad'
    },
    showShortTips() {
      const tips = this.shortTips
      if (typeof tips === 'string') {
        return this.autoPrefix('2ad10e6')
      } else {
        return `${tips}s`
      }
    },
  },
  created() {
    // this.handleTest()
  },
  methods: {
    handleTest() {
      if (window.location.host === 'localhost:8080') {
        this.commonForm = {
          userName: 'dsadsadaaa1',
          phone: '18865222222',
          graphical: '1234',
          short: 123465,
          email: '2525@qqsa.com',
          password: 'qaz123465',
          confirmPassword: 'qaz123465',
          agreement: true,
        }
      }
    },
    showAgreement() {
      this.visible = true
    },
    handleRegister() {
      if (this.registerType === 'PERSON') {
        this.userRegister()
      } else {
        this.$emit('submitForm')
      }
    },
    creatData() {
      const form = this.commonForm
      const data = {
        country: this.abroad ? 'RUSSIA' : 'CHINA',
        code: form.graphical,
        password: base64Password(form.password),
        confirmPassword: base64Password(form.confirmPassword),
        email: form.email,
        phone: form.phone,
        smsCode: form.short,
        username: form.userName,
      }
      return data
    },
    userRegister() {
      const data = this.creatData()
      API.account
        .userRegister(data)
        .then((res) => {
          if (res.code === '000000') {
            this.goLogin()
            this.$message.success('注册成功，请登录')
          } else {
            this.$refs.GraphicalCode.refresh()
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          log('Interface exception API.account.userRegister()')
        })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    geEn(key) {
      const i18n = this.$i18n
      return i18n._getMessages().en[key] || key
    },
    goLogin() {
      this.$router.push({
        path: '/account/login',
      })
    },
    handleGetSmsCodeResult(res) {
      if (res.code === '000000') {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    getEmailCode() {
      API.account
        .getEmailCode({
          email: this.commonForm.email,
          type: 'REGISTER',
        })
        .then((res) => {
          this.handleGetSmsCodeResult()
        })
        .catch(() => {
          log('Interface exception API.account.userRegister()')
        })
    },
    sendSms() {
      API.account
        .sendSms({
          phone: this.commonForm.phone,
          smsType: 'REGISTER',
        })
        .then((res) => {
          this.handleGetSmsCodeResult()
        })
        .catch(() => {
          log('Interface exception API.account.userRegister()')
        })
    },
    handleShortTips() {
      if (typeof this.shortTips === 'string') {
        let key = 'phone'
        if (this.abroad) {
          key = 'email'
        }
        this.$refs.commonForm.validateField([key], (verifycode_check) => {
          if (verifycode_check) return
          this.changeShortTips()
          this.getSmsCode()
        })
      }
    },
    getSmsCode() {
      if (this.abroad) {
        this.getEmailCode()
      } else {
        this.sendSms()
      }
    },
    changeShortTips() {
      this.shortTips = 60
      const timeoutId = setInterval(() => {
        if (this.shortTips > 0) {
          this.shortTips -= 1
        }
        if (this.shortTips === 0) {
          this.shortTips = this.autoPrefix('2ad10e6')
          clearTimeout(timeoutId)
        }
      }, 1000)
    },
    lastStep() {
      this.$emit('lastStep')
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleRegister()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    register(type) {
      this.$emit('changeRegisterType', type)
    },
    resetForm() {
      this.$refs['commonForm'].resetFields()
    },
  },
}
</script>

<style lang="less">
/* 注册页面用户名提示 */
.userNamePopover {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  background: rgba(0, 0, 0, 0.75);
  word-break: normal;
  padding: 10px 20px;
  .popper__arrow::after {
    border-top-color: rgba(0, 0, 0, 0.75) !important;
  }
}
</style>
<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';

.commonForm {

  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  & /deep/ .loginButton {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    height: 40px;
    background: #00a4ff;
    border-radius: 4px;
  }
  & /deep/ .commonButtonContainer {
    .el-form-item__content {
      display: flex;
      justify-content: center;
    }
    .commonButton {
      position: relative;
      width: 160px;
      height: 40px;
      border-radius: 4px;
    }
  }

  & /deep/ .el-input__inner {
    // border-radius: 23px;
  }
  & /deep/ .el-form-item__content {
    width: 360px;
  }
  .graphical {
    position: relative;
    .uiImg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 80px;
      height: 34px;
      padding: 4px 0 2px 10px;
      margin-right: 10px;
      border-left: 2px solid #efefef;
      cursor: pointer;
    }
  }
  .short {
    position: relative;
    .uiText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      padding: 0 10px;
      border-left: 2px solid #efefef;
      font-size: 14px;
      color: #cccccc;
      text-align: center;
      cursor: pointer;
    }
  }

  .agreement {
    & /deep/ .el-checkbox__label {
      display: inline-grid;
      white-space: pre-line;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 20px;
    }
    .uiText {
      color: #00a4ff;
      cursor: pointer;
    }
  }
}

</style>

<template>
  <div class="content">
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Комитет управления пилотной зоны регионального торгово-экономического
        сотрудничества «Китай-ШОС» </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >далее – КУ «КИТАЙ-ШОС»</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >обращает ваше внимание на то, что:</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">[</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Тщательное ознакомление</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">]</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Перед выполнением регистрации тщательно ознакомьтесь с</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        настоящ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">им</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        соглашение</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">м,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        чтобы точно понять содержание различных статей, особенно</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        статьи,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        касающи</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ся освобождения от ответственности или ограничения ответственности. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >сли не согласи</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >тесь </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >с настоящим соглашением или любым его услови</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ем,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        то процесс регистрации не может быть завершен. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >осле </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >завершения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистрации, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >вы лишитесь права </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ссылаться на неозна</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >комление с настоящим соглашением в </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >своем </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >возражени</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >в любой форме.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅰ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. И</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >нформация о регистрации</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ри пользовании </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >услугами, оказанными на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        больших данных  юридическ</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ий услуг, связанных с заграничной деятельностью</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, пользователь должен предоставлять информацию по подсказке страницы
        платформы, и обеспечить своевременное обновление информации, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >в целях того, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >чтобы платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >оказала качественные услуги.
      </span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >При обнаружении того, что</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >любой пользователь </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистрирует</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        учетную запись </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >с ложной информацией или </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">за</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистрир</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ованная </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >информаци</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >я связана с нарушением закона</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, то платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >имеет право в одностороннем порядке принимать меры, включая, но не
        ограничиваясь, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >требование устранения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >в установленный срок, приостановление использования или аннулирование </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и т.д.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Пользователь обязан </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >обеспечить, чтобы в регистрационной информации, как наименования </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >аватаре и т.д., не</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >законные и нежелательные информации. </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >При нарушении данного пункта в процессе </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >предоставлен</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ия информации, пользователь</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        должен нести соответств</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ующие </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ответственности и последствия, и платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        оставляет за собой право прекратить использование пользователем
        услуг</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, оказанных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ой</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        или принять</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        другие</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        меры обра</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >щения. В случае причинения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ущерб</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ов</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        платформе или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >КУ «КИТАЙ-ШОС», КУ «КИТАЙ-ШОС» </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >имеет право </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >требовать пользователь возмести их.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">В</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        качестве поставщика </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформенных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >услуг </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, платформа больших данных о юридических услуг, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >связанных с заграничной деятельностью, имеет право требовать от
        пользователя выполнить подтверждение личности в соответствии с
        действующим законодательством, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >чтобы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >представить пользователям более качественные услуги, и обеспечить
        б</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >езопасность</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        учетной записи каждого </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователя</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅱ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >равила безопасности </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, разрешает </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >каждому пользователю зарегистрировать только од</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ну учетную запись на</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >своей </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >е, тем более, пользователь должен подтвердить свою личность. После
        завершения р</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >егистрации, пользователь сам устанавливает и хранит</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        свою учетную запись, платформа никогда </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >не будет просить пользователя предоставить пароль </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи. В</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        связи с этим</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, хорошенько со</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >храните информацию о</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">б</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи, и подлежащим образом выйти из логина, и покинуть
        платформу</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        больших данных </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >связанн</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ых с заграничной деятельностью.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователь несет ответственность за безопасность </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >свой учетной записи</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и за </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >поведения</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, совершенные через </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >свою учетную запись</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, не несет ответственности за убытки и последствия из - за того, что
        пользователь по собственной инициативе разглашает или подвергается
        нападению, мошенничеству и другим действиям.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        При этом, платформа больших данных о юридических услугах, связанных с
        заграничной деятельностью, и КУ «КИТАЙ-ШОС» не несет любую
        ответственность.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅲ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >равила </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >услуг </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">К</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >онкретное содержание </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >услуг</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        зависит от фактического представления платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >сли не указано иное, новые продукты, функции и услуги, созданные
        платформой </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, регулируются настоящим соглашением.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">С </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетом особого характера сетевых услуг платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, оставляет </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >за собой право в любое время изменять, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >прекращать </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >расторгнуть </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >частичные или все сетевые услуги, и не несет </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ответственности перед пользователем или третьей стороной. </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Если платформа больших данных о юридических услуг, связанных с
        заграничной деятельностью, не может нормальность оказать услуги в
        случаях </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >форс-мажорны</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">х</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        обстоятельств</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформа больших данных о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        не несет ответственность, но </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >она должна </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >принимать </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >подлежащие </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >меры для скорейшего восстановления нормальн</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ых услуг.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, является </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ой</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        для доступа пользователей к информации о юридических услугах, а не
        посредник</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ом</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >В случае споров, возникших в отношениях доверенности, установленных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >между пользователем и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридическим лицом или физическим лицом </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридическ</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >их услуг на  плат</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >форм</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, по собственному желанию, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформа больших данных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        не несет ответственности.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >не предоставляет никаких гарантий и не гарантирует, что услуги будут
        отвечать требованиям пользователей. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, представляет собой публичную </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">у</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услуг, представляет такие же услуги, включая но не
        ограничиваясь, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридическими консультациями, запросом информации, оформлением
        руководства по правовым вопросам и т.д., </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформа представляет их только для информации, не подтверждает их
        подлинность. За дела, находящиеся в судебной процедуре, или в процессе
        рассмотрения судебными органами, правоохранительными органами, или уже
        законченные, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформа больших данных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >КУ «Китай ШОС» не</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        несут ответственности</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅳ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >равила поведения пользователей</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ользователь не должен злонамеренно регистрировать </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетную запись на</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        больших данных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >не должен </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">по</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">дар</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ить</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >одалживать</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, арендовать, передавать или иным образом разрешать </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >иному лицу использовать учетную запись.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ри пользовании услуг</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ами</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, предоставляемы</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ми</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        платформой </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, пользователь должен </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соблюдать требования государственных законов, правил, нормативных
        документов и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистраци</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ю на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >не должен осуществлять нижеследующие поведения за счет </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ы</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью:
      </span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >убликация, передача, распространение и хранение материалов,
        противоречащих государственным законам и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >нормативным актам</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, создающих угрозу национальной безопасности</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и единству</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, подстрекающих к национальной ненависти и дискриминации, подрывающих
        национальное единство, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >нарушающих </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        религиозную политику государства, пропагандирующих секты и феодальные
        убеждения, распространяющих слухи, нарушающих общественный порядок,
        подрывающих социальную стабильность, нарушающих публичный порядок,
        оскорбляющих, клеветнических, непристойных, насильственных</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        материалов</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и т.д.;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >убликация, передача, распространение, хранение материалов, нарушающих
        законные права на честь, неприкосновенность частной жизни, торговые
        знаки, авторские права, патенты, портреты, коммерческую тайну и
        т.д.;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ф</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >альсификация фактов, сокрытие правды, введение в заблуждение, обман
        других лиц;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">4)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ублик</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ация, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >переда</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ча, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >и распростране</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ние </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >рекламн</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ых</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        информаци</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">й</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >спамов</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(5</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Нарушение, измен</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ние, удал</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ние данных, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >влия</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ющих </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >на нормальную эксплуатацию системы платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >несанкционированное получение данных других </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователей платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, без уполномочия</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(6)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >С помощью ПО, плагинов, хаков, разработанных и лицензированных третьей
        стороной, посещать п</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">у</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        больших данных не</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услуг, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >и пользоваться её услугами, или</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        изготовить, опубликовать и распространить вышеуказанные
        инструменты;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">7)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Остальные поведения, которые </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >наруш</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ают </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >закон</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ы, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >правил</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">а</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, нормативны</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >е акты или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашени</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистраци</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ю</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, и препятствуют нормальной эксплуатации </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Пользователь</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        должен самостоятельно нести юридическую ответственность за претензии
        третьей стороны, возникшие из - за нарушения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователем</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        положений настоящего </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >сли нарушение </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователем</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        приводит к убыткам для платформы или</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        КУ «Китай ШОС», КУ «Китай ШОС»</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        имеет право взыскать</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> их</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> с </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователя</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅴ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Расторжение </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">5</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">З</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >арегистрированный пользователь вправе расторгнуть настоящее соглашение
        любым из следующих способов:</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">В</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ыполнить аннулирование</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        учетной записи</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        по процессу аннулирования </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >учетной записи </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Д</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >о вступления в силу </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистраци</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ю</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >на п</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >заинтересованных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >изменений политики</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователь</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        самовольно прекращает использование и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >уведомляет </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформу </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью, </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >несогласии принимать изменения</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Другой способ </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >прекращения действия соглашения, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >установленный </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформой больших данных о других </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">5</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >сли платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        сам</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">а</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        обнаруживает или получает </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >донесение </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >или жалобу от третьей стороны, </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >узнает о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >том, что пользователь нарушает или оказывается во подозрении в
        нарушении обязанностей, ответственностей или статьей в настоящем
        соглашении, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформа больших данных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        имеет право по разумному суждению, немедленно принимать необходимые меры
        для уменьшения или устранения влияния поведения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователя</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        при этом,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователь</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        несет ответственность за потери и последствия из - за этого.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">«Н</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >еобходимые меры</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">»</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, указанные в настоящем соглашении, включают, но не ограничиваются
        одним или несколькими из следующих</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        пунктов</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">:</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >риостановить или прекратить оказание услуг пользователям частично или
        полностью;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(2</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">З</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >аморажива</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ть или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >аннулирова</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ть учетную запись </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользовател</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">я</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(3</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Другие </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >меры, которые</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        необходимыми считается платформа больших данных юридических услугах,
        связанных с заграничной деятельностью.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">5</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >осле </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >расторжения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >настоящего соглашения, платформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услуг, связанных с заграничной
        деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        не обязана раскрывать пользователю или </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >его </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >назначенной третьей стороне любую информацию </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >в учетной записи </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователя, и не обязана передавать любую информацию пользователю или
        третьей стороне</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, если иное не установлено законом</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">5</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >осле </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >расторжения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >настоящего соглашения платформа больших данных о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        по - прежнему имеет следующие права:</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >родолжать сохранять информацию, оставленную пользователем на платформе
        больших данных юридических услуг</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, связанных с заграничной деятельностью</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(2)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">В</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        отношени</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >и предыдущего</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        нарушени</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >я соглашения с</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >о стороны пользователя, платформа больших данных </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услугах, связанных с заграничной деятельностью, оставляет
        за собой право </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >привлечь к ответственности за нарушение </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >соглашения</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅵ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >рава интеллектуальной собственности</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Е</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >сли не указано иное, все </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >продукты, технологии, программны</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >е обеспечени</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">я</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, программы, данные и другая информация и связанные права, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >находящиеся на </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >платформ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">е</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        больших данных </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услуг</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ах, связанных с заграничной деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >принадлежат платформе больших данных </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">о </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >юридических услуг</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ах, связанных с заграничной деятельностью. Н</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >икто не может самовольно использовать или использовать в коммерческих
        целях без разрешения платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услугах, связанных с загра</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ничной деятельностью</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅶ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Защита </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >личных</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >данных</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >пользователей</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">7</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ользователь согласен с тем, что платформа больших данных</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        юридических услугах, связанных с заграничной деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >собирает, использует, хранит, делится и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >охраняет личную </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >информацию пользователя согласно </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >«Постановлению ПК ВСНП</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        об усилении защиты сетевой информации</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">»</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">, </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">«П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >равилам защиты личной информации пользователя </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >по телекоммуникации </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >и интернет</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >у», утвержденным</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        министерств</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">ом</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        промышленности и информатизации </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >приказ № </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">24</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        министерства промышленности и информатизации</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">,</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и настоящему соглашению.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">7</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >В процессе собрания и использования личной информации согласно
        законодательству и нормативным актам, п</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >латформа </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услугах, связанных с заграничной
        деятельностью, должна </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >придерживается следующих принципов:</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">Н</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >е собира</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ет и использует</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        информацию </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >путем </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >обман</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >а, введения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >в заблуждение или прину</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ждения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >или нарушени</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">я</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        законов, административных положений и соглашений сторон;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(2)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> Н</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >е собирает </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >личные информации </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >о пользователя</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        вне необходимого объема, или использует собранные личные информации
        пользователя в </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >иных целях, чем предоставление услуг;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">(</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3)</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt"> </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >Сохраняет личные информации пользователей, собранные в процессе
        оказания услуг, в тайне, не разглашает, фальсифицирует, или уничтожает
        их, не продает или незаконно передает их, за исключением случаев, когда
        судебные или административные органы в установленном законом порядке
        требуют платформе больших данных юридических услугах, связанны</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >х с заграничной деятельностью, предоставить их.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">7</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">З</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ащита личной информации пользователя является основной политикой
        платформы </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услугах, связанных с заграничной
        деятельностью. Платформа больших данных о юридических услугах, связанных
        с заграничной деятельностью, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >гарантирует</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        не разглашать или передавать третьей стороне </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >регистрационные </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >информации и непубличные данные пользователя на своей платформе, </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >если законодательством не указано иное.</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Ⅷ</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">. </span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >рочее</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">8</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">Д</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >ействие, толкование, изменение, исполнение и </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >разрешение </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >споров настоящего соглашения </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >находятся регулированием законодательства и нормативных актов КНР. При
        возникновения разногласия или споров </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >между пользователем и платформой </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >больших данных о юридических услугах, связанных с заграничной
        деятельностью, обе стороны могут разрешить их путем переговоров. При
        невозможности их разрешать переговорами, обе стороны могут подать из на
        разбирательство в компетентный народный суд в местности</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >, где находится КУ «Китай-ШОС».</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">8</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">.</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >&#xa0;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 'Times New Roman'; font-size: 10.5pt">П</span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >раво на изменение, толкование</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        положений соглашения</span><span style="font-family: 'Times New Roman'; font-size: 10.5pt">
        и толкование </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >использования </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >данной системы принадлежит </span><span
        style="font-family: 'Times New Roman'; font-size: 10.5pt"
      >КУ «Китай-ШОС».</span>
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.content {
  p {
    padding-top: 10px;
  }
  // p:first-child, p:first-child>span {
  //   font-size: 20px !important;
  //   text-align: center !important;
  //   font-weight: bold;
  // }
}
</style>

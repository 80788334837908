import { render, staticRenderFns } from "./CommonForm.vue?vue&type=template&id=0653f210&scoped=true&"
import script from "./CommonForm.vue?vue&type=script&lang=js&"
export * from "./CommonForm.vue?vue&type=script&lang=js&"
import style0 from "./CommonForm.vue?vue&type=style&index=0&lang=less&"
import style1 from "./CommonForm.vue?vue&type=style&index=1&id=0653f210&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0653f210",
  null
  
)

export default component.exports
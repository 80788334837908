<template>
  <div class="content">
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span
        style="font-family: Arial; font-size: 11pt"
      >China-Shanghai Cooperation Organization Local Economic and Trade
        Cooperation Demonstration Zone Management Committee (hereinafter
        referred to as "SCO Management Committee") reminds you:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span
        style="font-family: Arial; font-size: 11pt"
      >[Read carefully] Please read this agreement carefully before </span><span
        style="font-family: Arial; font-size: 11pt"
      >registering, and understand the contents of each clause accurately,
        especially the clauses on exemption or limitation of liability. If you
        do not agree to this agreement or any of its terms, you will not be able
        to complete the registration process. After </span><span
        style="font-family: Arial; font-size: 11pt"
      >registration, you must not make any form of defense on the grounds of
        not reading this agreement.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">I.</span><span style="font-family: Arial; font-size: 11pt">
        Registration Information</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">1.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">When using the </span><span
        style="font-family: Arial; font-size: 11pt"
      >Foreign-related Legal Services Mega-Data Platform</span><span style="font-family: Arial; font-size: 11pt"> </span><span style="font-family: Arial; font-size: 11pt">(</span><span
        style="font-family: Arial; font-size: 11pt"
      >hereinafter referred to as "</span><span style="font-family: Arial; font-size: 11pt">the Platform</span><span style="font-family: Arial; font-size: 11pt">”</span><span style="font-family: Arial; font-size: 11pt">) </span><span
        style="font-family: Arial; font-size: 11pt"
      >services, users should follow the prompts on </span><span style="font-family: Arial; font-size: 11pt">the Platform</span><span style="font-family: Arial; font-size: 11pt">
        page to provide information truthfully, accurately and completely, and
        ensure that the information is updated in time, so that </span><span style="font-family: Arial; font-size: 11pt">the Platform</span><span style="font-family: Arial; font-size: 11pt"> can</span><span style="font-family: Arial; font-size: 11pt"> serve</span><span style="font-family: Arial; font-size: 11pt"> users</span><span style="font-family: Arial; font-size: 11pt"> better</span><span style="font-family: Arial; font-size: 11pt">.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">1.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">If </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        discovers that users use false information for account registration or
        the registration information</span><span style="font-family: Arial; font-size: 11pt">
        violates laws and regulations,</span><span style="font-family: Arial; font-size: 11pt"> t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        has the right to unilat</span><span
        style="font-family: Arial; font-size: 11pt"
      >erally take measures including but not limited to corrections within a
        time limit, suspension of use, or account cancellation.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">1.3</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >The user shall ensure that no illegal or bad information appears in the
        registration information such as account name and</span><span style="font-family: Arial; font-size: 11pt">
        avatar. If the information provided by the user violates the
        requirements of this paragraph, the user shall bear the corresponding
        responsibilities and consequences arising </span><span style="font-family: Arial; font-size: 11pt">therefore</span><span style="font-family: Arial; font-size: 11pt">, and </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> reserves the ri</span><span
        style="font-family: Arial; font-size: 11pt"
      >ght to terminate the user’s use of the services of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        or take other processing measures. If </span><span style="font-family: Arial; font-size: 11pt">the Platform</span><span style="font-family: Arial; font-size: 11pt">
        or the SCO Management Committee </span><span style="font-family: Arial; font-size: 11pt">gets</span><span style="font-family: Arial; font-size: 11pt">
        losses, the SCO Management Committee has the right to recover com</span><span
        style="font-family: Arial; font-size: 11pt"
      >pensation from users.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">1.4</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">As a provider of </span><span style="font-family: Arial; font-size: 11pt">the</span><span style="font-family: Arial; font-size: 11pt"> </span><span style="font-family: Arial; font-size: 11pt">P</span><span
        style="font-family: Arial; font-size: 11pt"
      >latform services, in order to enable users to better use the services
        of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, and to ensure the security of users’ accoun</span><span style="font-family: Arial; font-size: 11pt">ts, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        has the right to require users to comply with Chinese laws and
        regulations </span><span
        style="font-family: Arial; font-size: 11pt"
      >in order to complete</span><span style="font-family: Arial; font-size: 11pt">
        the real-name authentication.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">II.</span><span style="font-family: Arial; font-size: 11pt">
        Account Security Regulations</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">2.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt">
        only allows each user to register for </span><span style="font-family: Arial; font-size: 11pt">the P</span><span style="font-family: Arial; font-size: 11pt">latform </span><span style="font-family: Arial; font-size: 11pt">one </span><span
        style="font-family: Arial; font-size: 11pt"
      >account and the user needs to complete real-name authentication. After
        the registration is passed, the user sets up and keeps the account by
        hims</span><span style="font-family: Arial; font-size: 11pt">elf, and </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        will not actively ask the user to provide the account password at any
        time. Therefore, please be sure to keep your account information, and
        ensure that you log out at the end of each online perio</span><span style="font-family: Arial; font-size: 11pt">d and </span><span style="font-family: Arial; font-size: 11pt">follow</span><span style="font-family: Arial; font-size: 11pt">
        the correct steps.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">2.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >The user shall be responsible for the security of the account and the
        actions implemented through the account. The account shall not be held
        responsible for the losses and consequences caused by the user's active </span><span
        style="font-family: Arial; font-size: 11pt"
      >disclosure or the attack or fraud of others. </span><span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt"> and the </span><span
        style="font-family: Arial; font-size: 11pt"
      >SCO Management Committee</span><span style="font-family: Arial; font-size: 11pt">
        shall not be liable.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">III. </span><span
        style="font-family: Arial; font-size: 11pt"
      >Service specifications of </span><span style="font-family: Arial; font-size: 11pt">the P</span><span style="font-family: Arial; font-size: 11pt">latform</span><span style="font-family: Arial; font-size: 11pt"> </span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">3.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >The specific content of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        service is subject to the actual presentation of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >. Unless otherwise stated, the new products, new functions, and new
        servic</span><span style="font-family: Arial; font-size: 11pt">es launched by </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> are </span><span style="font-family: Arial; font-size: 11pt">regulated</span><span style="font-family: Arial; font-size: 11pt">
        by this agreement.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">3.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >In view of the particularity of network services, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        reserves the right to change, interrupt or </span><span
        style="font-family: Arial; font-size: 11pt"
      >terminate some or all of the network services at any time, and is not
        liable to users or third parties. </span><span style="font-family: Arial; font-size: 11pt">If d</span><span
        style="font-family: Arial; font-size: 11pt"
      >ue to force majeure or unexpected events, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> </span><span style="font-family: Arial; font-size: 11pt">couldn</span><span style="font-family: Arial; font-size: 11pt">’</span><span style="font-family: Arial; font-size: 11pt">t </span><span
        style="font-family: Arial; font-size: 11pt"
      >provide services normally, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        shall not be liable, but </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        will take reasonable actions to resume normal services as soon as
        possible.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">3.3</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt"> only serves as</span><span style="font-family: Arial; font-size: 11pt">
        a platform for users to obtain legal service information, not an
        intermediary agency. According to their own needs, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        shall not be liable for disputes arising from the establishment of an
        entrusted relatio</span><span
        style="font-family: Arial; font-size: 11pt"
      >nship with the on-site legal service agency or legal service personnel
        of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> voluntarily.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">3.4</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt">
        does not provide any type of guarantee, nor does it promi</span><span
        style="font-family: Arial; font-size: 11pt"
      >se that the service will meet the requirements of users. As a public
        legal service platform, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        provides services based on the information provided by users, including
        but not limited to legal consultation, </span><span
        style="font-family: Arial; font-size: 11pt"
      >information inquiry, and guidelines for handling legal affairs. They
        are only for users’ reference. The authenticity of relevant information
        is </span><span
        style="font-family: Arial; font-size: 11pt"
      >not to be confirmed by t</span><span style="font-family: Arial; font-size: 11pt">he </span><span style="font-family: Arial; font-size: 11pt">P</span><span style="font-family: Arial; font-size: 11pt">latform. </span><span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt"> </span><span
        style="font-family: Arial; font-size: 11pt"
      >and the SCO Management Committee shall not bear any responsibility for
        various cases that are in the process of litigation or that are being
        handled by judicial or administrative law enforcement agencies, as well
        as various cases that have been completed a</span><span style="font-family: Arial; font-size: 11pt">nd effective.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">IV.</span><span style="font-family: Arial; font-size: 11pt"> </span><span style="font-family: Arial; font-size: 11pt">U</span><span style="font-family: Arial; font-size: 11pt">ser</span><span style="font-family: Arial; font-size: 11pt">’</span><span style="font-family: Arial; font-size: 11pt">s</span><span style="font-family: Arial; font-size: 11pt"> code of conduct</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">4.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >Users may not maliciously register an account on </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, and may not give away, borrow, rent, transfer or otherwise permit
        others to use the registered account.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">4.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >When using the services provided by </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, users must abide by national laws, regulations, regulatory documents,
        and the requirements of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> registration</span><span style="font-family: Arial; font-size: 11pt">
        agreement, and must not use </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        services to engage in the following behaviors:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(1) Publishing, transmitting, disseminating, and storing violations of
        national laws and regulations, endangering national se</span><span
        style="font-family: Arial; font-size: 11pt"
      >curity and unity, inciting ethnic hatred and ethnic discrimination,
        undermining ethnic unity, undermining national religious policies,
        promoting cults and feudal superstition, spreading rumors, disrupting
        social order, and undermining Social stability, vio</span><span
        style="font-family: Arial; font-size: 11pt"
      >lation of public order and good customs, insults, slander, obscenity,
        violence, etc.;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(2) Publishing, transmitting, disseminating, and storing content that
        infringes on the legal rights of others' reputation, privacy, trademark,
        copyright, patent rights</span><span
        style="font-family: Arial; font-size: 11pt"
      >, portrait rights, and trade secrets;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(3) Fabricating facts, concealing the truth, misleading or deceiving
        others;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(4) Publishing, transmitting and disseminating advertising information
        and junk information;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(5) Destroying, tampering, deleting, af</span><span
        style="font-family: Arial; font-size: 11pt"
      >fecting the normal operation of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        system, or obtaining unauthorized and unauthorized data from other users
        of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(6) Log in or use foreign-related legal </span><span
        style="font-family: Arial; font-size: 11pt"
      >service big data platforms and services through third-party software,
        plug-ins, and plug-in systems developed and authorized by
        non-foreign-related legal service big data platforms, or make, publish,
        or disseminate the aforementioned tools;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span style="font-family: Arial; font-size: 11pt">(7) Other ac</span><span
        style="font-family: Arial; font-size: 11pt"
      >ts that violate laws, regulations, or regulatory documents, or violate </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        registration agreement, and interfere with the normal provision of
        services by </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">4.3</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >The user shall independently bear legal responsibility for third-party
        claims caused by the user's violation of the provisions of this
        agreement.</span><span style="font-family: Arial; font-size: 11pt">
        If the user breaches the contract and causes losses to </span><span style="font-family: Arial; font-size: 11pt">the Platform</span><span style="font-family: Arial; font-size: 11pt">
        or the SCO Management Committee, th</span><span
        style="font-family: Arial; font-size: 11pt"
      >e SCO Management Committee has the right to recover from the
        user.</span><span style="font-family: Arial; font-size: 11pt"> </span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">V</span><span
        style="font-family: Arial; font-size: 11pt"
      >. Termination of the agreement</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">5.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >Registered users have the right to terminate this agreement in any of
        the following ways:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(1) Complete the account cancellation process in </span><span
        style="font-family: Arial; font-size: 11pt"
      >accordance with the account cancellation process of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span style="font-family: Arial; font-size: 11pt">(2) Before </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        registration agreement and related policy changes take effect, the user
        voluntarily s</span><span
        style="font-family: Arial; font-size: 11pt"
      >tops using it and informs </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        that he is unwilling to accept the changes.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(3) Other methods for terminating the agreem</span><span
        style="font-family: Arial; font-size: 11pt"
      >ent determined by the </span><span style="font-family: Arial; font-size: 11pt">P</span><span style="font-family: Arial; font-size: 11pt">latform</span><span style="font-family: Arial; font-size: 11pt">.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">5.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 22pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">If </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        discovers on its own or receives a third-party report or complaint, and
        learns that the user exists or is suspected of violating the
        obligations, guarantees or other </span><span
        style="font-family: Arial; font-size: 11pt"
      >terms stipulated in this agreement, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        has the right to act immediately based on reasonable judgments Necessary
        measures to reduce or eliminate the impact of user behavior, the losses
        and consequences caused by this shall be borne by the user.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >The "necessary measures" mentioned in this agreement include but are
        not limited to one or more of the following:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(1) Suspend or terminate the provision of some or all services to
        users;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(2) Freeze or cancel user accounts;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(3) Other measures dee</span><span style="font-family: Arial; font-size: 11pt">med necessary by </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">5.3</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >After the termination of this agreement, except as expressly provided
        by law, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        has no obligation to disclose any information i</span><span
        style="font-family: Arial; font-size: 11pt"
      >n the user account to the user or a third party designated by the user,
        nor is it obligated to forward any information to the user or third
        party.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">5.4</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >After the termination of this agreement, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> still e</span><span
        style="font-family: Arial; font-size: 11pt"
      >njoys the following rights:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(1) Continue to save all kinds of information retained by users on </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >(2) For users' past breaches of contract, </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> can </span><span
        style="font-family: Arial; font-size: 11pt"
      >still be held accountable for breach of contract in accordance with the
        law.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">VI</span><span
        style="font-family: Arial; font-size: 11pt"
      >. Intellectual Property</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >Unless otherwise stated by </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, all products, technologies, software, programs, data and other
        infor</span><span
        style="font-family: Arial; font-size: 11pt"
      >mation and related rights in </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt"> belong to </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >. Without the permission of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, no one may use or use it for comm</span><span
        style="font-family: Arial; font-size: 11pt"
      >ercial purposes without authorization.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">VII</span><span
        style="font-family: Arial; font-size: 11pt"
      >. Protection of users' personal information</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">7.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 21pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >The user agrees to </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">
        in accordance with the "Decision of the Standing Committee of the
        National People's Congr</span><span
        style="font-family: Arial; font-size: 11pt"
      >ess on Strengthening the Protection of Network Information", the
        Ministry of Industry and Information Technology "Provisions on the
        Protection of Personal Information of Telecommunications and Internet
        Users" (Order No. 24 of the Ministry of Industry and I</span><span
        style="font-family: Arial; font-size: 11pt"
      >nformation Technology) The relevant regulations and this agreement
        collect, use, store, share and protect users’ personal
        information.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">7.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 21pt;
      "
    >
      <span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt">
        legally and properly collects and uses personal information i</span><span
        style="font-family: Arial; font-size: 11pt"
      >n accordance with relevant laws and regulations, and follows the
        following principles:</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span
        style="font-family: Arial; font-size: 11pt"
      >(1) Not to collect or use information by deceiving, misleading or
        coercive methods, or in violation of laws, administrative regulations,
        and agreements between the </span><span style="font-family: Arial; font-size: 11pt">parties;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span
        style="font-family: Arial; font-size: 11pt"
      >(2) Do not collect user personal information other than necessary for
        the provision of services or use the information for purposes other than
        the provision of services;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span
        style="font-family: Arial; font-size: 11pt"
      >(3) The personal information of users collected and used in the process
        o</span><span
        style="font-family: Arial; font-size: 11pt"
      >f providing services shall be strictly kept confidential, and shall not
        be disclosed, tampered with or destroyed, and shall not be sold o</span><span
        style="font-family: Arial; font-size: 11pt"
      >r illegally provided to others, </span><span style="font-family: Arial; font-size: 11pt">e</span><span
        style="font-family: Arial; font-size: 11pt"
      >xcept for the situation </span><span style="font-family: Arial; font-size: 11pt">j</span><span
        style="font-family: Arial; font-size: 11pt"
      >udicial or administrative </span><span style="font-family: Arial; font-size: 11pt">authority</span><span style="font-family: Arial; font-size: 11pt"> </span><span style="font-family: Arial; font-size: 11pt">require </span><span style="font-family: Arial; font-size: 11pt">the Platform </span><span style="font-family: Arial; font-size: 11pt">based on leg</span><span
        style="font-family: Arial; font-size: 11pt"
      >al procedures to provide</span><span style="font-family: Arial; font-size: 11pt">.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">7.3</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p
      style="
        font-size: 11pt;
        line-height: 115%;
        margin: 12pt 0pt;
        text-indent: 21pt;
      "
    >
      <span
        style="font-family: Arial; font-size: 11pt"
      >Protecting users’ personal information is a basic policy of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span style="font-family: Arial; font-size: 11pt">. </span><span style="font-family: Arial; font-size: 11pt">The Platform</span><span style="font-family: Arial; font-size: 11pt"> guarantees</span><span style="font-family: Arial; font-size: 11pt">
        that it will not disclose or provide the user’s registration information
        to</span><span style="font-family: Arial; font-size: 11pt">
        third parties and store it in </span><span style="font-family: Arial; font-size: 11pt">t</span><span
        style="font-family: Arial; font-size: 11pt"
      >he non-public content of </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, unless otherwise provided by laws and regulations.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">VIII</span><span style="font-family: Arial; font-size: 11pt">. Other</span><span style="font-family: Arial; font-size: 11pt"> artic</span><span style="font-family: Arial; font-size: 11pt">les</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">8.1</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span
        style="font-family: Arial; font-size: 11pt"
      >The validity, interpretation, modification, execution and dispute
        resolution of this agreement shall be governed by the laws and
        regulations of the People's Republic of China. If there is a dispute or
        di</span><span
        style="font-family: Arial; font-size: 11pt"
      >spute between the user and </span><span style="font-family: Arial; font-size: 11pt">t</span><span style="font-family: Arial; font-size: 11pt">he Platform</span><span
        style="font-family: Arial; font-size: 11pt"
      >, it can be resolved through negotiation; if the negotiation fails, the
        dispute or dis</span><span
        style="font-family: Arial; font-size: 11pt"
      >pute shall be submitted to the </span><span style="font-family: Arial; font-size: 11pt">C</span><span
        style="font-family: Arial; font-size: 11pt"
      >ourt with jurisdiction where the SCO Management Committee is
        located.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">8.2</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 12pt 0pt">
      <span style="width: 36pt; text-indent: 0pt; display: inline-block"></span><span style="font-family: Arial; font-size: 11pt">The right to</span><span style="font-family: Arial; font-size: 11pt">
        change the terms, the right to interpret, and the right to interpret the
        use of this system belong to the SCO Management Committee.</span>
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 0pt">
      <span style="font-family: Arial; font-size: 11pt">&nbsp;</span>
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.content {
  p {
    padding-top: 6px;
    line-height: 14pt;
  }
  // p:first-child, p:first-child>span {
  //   font-size: 20px !important;
  //   text-align: center !important;
  //   font-weight: bold;
  // }
}
</style>

<template>
  <div class="content">
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >中国-上海合作组织地方经贸合作示范区管理委员会（以下简称“</span><span
        name="_Hlk61006994"
      ><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span></span><span style="font-family: 等线; font-size: 10.5pt">”）</span><span style="font-family: 等线; font-size: 10.5pt">提醒您：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        【审慎阅读】请在注册前认真阅读本协议，准确理解各条款内容，特别是其中关于免除或者限制责任的条款。如果不同意本协议或其中任何条款约定，将无法完成注册程序。注册后，不得以未阅读本协议为由作任何形式的抗辩。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">一、注册信息</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">1.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        在使用涉外法律服务大数据平台服务时，用户应当按涉外法律服务大数据平台页面的提示，真实</span><span style="font-family: 等线; font-size: 10.5pt">、</span><span style="font-family: 等线; font-size: 10.5pt">准确</span><span style="font-family: 等线; font-size: 10.5pt">、</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >完整地提供信息，并确保这些信息及时更新，以便涉外法律服务大数据平台更好地为用户服务。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">1.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        如涉外法律服务大数据平台发现用户以虚假信息进行账户注册或注册信息存在违法违规等情况，涉外法律服务大数据平台有权单方采取</span><span style="font-family: 等线; font-size: 10.5pt">包括但不限于</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >限期改正、暂停使用或注销账户等措施。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">1.3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p
      style="
        margin: 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 20pt;
        widows: 0;
      "
    >
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >用户应当保证在账号名称、头像等注册信息中不得出现违法和不良信息。如果用户提供的资料违反本款要求，用户需承担因此引起的相应责任及后果，并且涉外法律服务大数据平台保留终止用户使用涉外法律服务大数据平台各项服务或采取其他处理措施的权利</span><span style="font-family: 等线; font-size: 10.5pt">，给平台</span><span style="font-family: 等线; font-size: 10.5pt">或</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">造成损失的，</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">有权</span><span style="font-family: 等线; font-size: 10.5pt">向用户</span><span style="font-family: 等线; font-size: 10.5pt">追偿</span><span style="font-family: 等线; font-size: 10.5pt">。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">1.4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span><span style="font-family: 等线; font-size: 10.5pt">&nbsp;&nbsp; </span><span
        style="font-family: 等线; font-size: 10.5pt"
      >作为涉外法律服务大数据平台服务的提供者，为使用户更好地使用涉外法律服务大数据平台的各项服务，保障用户的账号安全，涉外法律服务大数据平台有权要求用户按照我国法律规定完成实名认证。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">二、账户安全规范</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">2.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        涉外法律服务大数据平台只允许每位用户注册一个涉外法律服务大数据平台账户且用户需完成实名认证。注册通过后，用户自行设置并保管账户，涉外法律服务大数据平台任何时候均不会主动要求用户提供账户密码。因此，请务必保管好账户信息，并确保每个上网时段结束时退出登录并以正确步骤离开涉外法律服务大数据平台。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">2.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        用户应对账户安全以及对通过账户实施的行为负责，账户因用户主动泄露或因遭受他人攻击、诈骗等行为导致的损失及后果，涉外法律服务大数据平台</span><span style="font-family: 等线; font-size: 10.5pt">及</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">不承担责任。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >三、涉外法律服务大数据平台服务规范</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">3.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; 涉外法律服务大数据平台服务的具体内容</span><span style="font-family: 等线; font-size: 10.5pt">以</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >涉外法律服务大数据平台</span><span style="font-family: 等线; font-size: 10.5pt">实际呈现为准</span><span style="font-family: 等线; font-size: 10.5pt">。</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >除另有说明，涉外法律服务大数据平台所推出的新产品、新功能、新服务，均受到本协议之规范。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">3.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        鉴于网络服务的特殊性，涉外法律服务大数据平台保留随时变更、中断或终止部分或全部网络服务的权利</span><span style="font-family: 等线; font-size: 10.5pt">，</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >且不对用户或第三方承担责任。因不可抗力</span><span style="font-family: 等线; font-size: 10.5pt">、意外事件</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >导致涉外法律服务大数据平台无法正常提供服务，涉外法律服务大数据平台不承担责任</span><span style="font-family: 等线; font-size: 10.5pt">，但</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >涉外法律服务大数据平台将采取合理行动尽快恢复正常服务。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">3.3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        涉外法律服务大数据平台仅作为用户获取法律服务信息的平台，并非中介机构。用户根据自身需求，因自愿与涉外法律服务大数据平台驻场法律服务机构或法律服务人员建立委托关系而产生的纠纷，涉外法律服务大数据平台不承担责任。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">3.4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        涉外法律服务大数据平台不提供任何类型的担保，也不承诺服务一定能满足用户的要求。
        涉外法律服务大数据平台作为公共法律服务平台，基于用户提供的信息所提供的</span><span style="font-family: 等线; font-size: 10.5pt">包括但不限于</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >法律咨询、信息查询、办理法律事务指引等服务，仅供用户参考</span><span style="font-family: 等线; font-size: 10.5pt">，对相关信息</span><span style="font-family: 等线; font-size: 10.5pt">的</span><span style="font-family: 等线; font-size: 10.5pt">真实性</span><span style="font-family: 等线; font-size: 10.5pt">，</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >涉外法律服务大数据平台不</span><span style="font-family: 等线; font-size: 10.5pt">予确认</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >。对于正处于诉讼程序或者司法机关、行政执法机关正在办理的各类案件，以及已经办结生效的各类案件，涉外法律服务大数据平台</span><span style="font-family: 等线; font-size: 10.5pt">及</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">不</span><span style="font-family: 等线; font-size: 10.5pt">承担任何责任。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">四、用户行为规范</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">4.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        用户不得恶意注册涉外法律服务大数据平台账户，不得赠与、借用、租用、转让或者以其他方式许可他人使用注册账户。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">4.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        用户在使用涉外法律服务大数据平台提供的服务时，必须遵守国家法律法规、规章、规范性文件及涉外法律服务大数据平台注册协议的要求，不得利用涉外法律服务大数据平台服</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >务从事以下行为：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （一）发布、传送、传播、储存违反国家法律法规、危害国家安全统一、煽动民族仇恨及民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、散布谣言，扰乱社会秩序，破坏社会稳定、违反公序良俗以及侮辱、诽谤、淫秽、暴力等内容；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （二）发布、传送、传播、储存侵害他人名誉权、隐私权、商标权、著作权、专利权、肖像权、商业秘密等合法权利的内容；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; （三）虚构事实、隐瞒真相，误导、欺骗他人；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; （四）发表、传送、传播广告信息及垃圾信息；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （五）破坏、篡改、删除、影响涉外法律服务大数据平台系统正常运行或未经授权非法获取涉外法律服务大数据平台其他用户的数据；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （六）通过非涉外法律服务大数据平台开发、授权的第三方软件、插件、外挂系统，登录或使用涉外法律服务大数据平台及服务，或制作、发布、传播上述工具；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （七）其他违反法律法规、规章、规范性文件或违反涉外法律服务大数据平台注册协议，干扰涉外法律服务大数据平台正常提供服务的行为。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">4.3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        用户违反本协议规定导致的第三方索赔，用户应当独立承担法律责任。</span><span style="font-family: 等线; font-size: 10.5pt">因用户违约</span><span style="font-family: 等线; font-size: 10.5pt">给平台</span><span style="font-family: 等线; font-size: 10.5pt">或</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">造成损失的，</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">有权向用户追偿</span><span style="font-family: 等线; font-size: 10.5pt">。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">五、协议的终止</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">5.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; 注册用户有权通过以下任</span><span style="font-family: 等线; font-size: 10.5pt">一</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >方式终止本协议：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （一）按照涉外法律服务大数据平台的账号注销流程，完成账号的注销；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （二）涉外法律服务大数据平台注册协议及相关政策变更事项生效前用户自行停止使用并告知涉外法律服务大数据平台不愿接受变更事项的。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （三）其他涉外法律服务大数据平台确定的终止协议的方式。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">5.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; 如果涉外法律服务大数据平台</span><span style="font-family: 等线; font-size: 10.5pt">自行</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >发现或收到第三方举报或投诉，获知用户存在或涉嫌违反本协议中约定的义务、保证或其他条款，涉外法律服务大数据平台有权依据合理判断，</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >立即采取必要措施以减轻或消除用户行为造成的影响，由此造成的损失及后果由用户承担。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        本协议中所述的“必要措施”包括但不限于以下一项或多项：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; （一）中止或终止向用户提供部分或全部服务；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; （二）冻结或注销用户账户；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; （三）其他涉外法律服务大数据平台认为</span><span style="font-family: 等线; font-size: 10.5pt">必要</span><span style="font-family: 等线; font-size: 10.5pt">的措施。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">5.3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        本协议终止后，除法律有明确规定外，涉外法律服务大数据平台无义务向用户或用户指定的第三方披露用户账户中的任何信息，也无义务向用户或第三方转发任何信息。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">5.4</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        本协议终止后，涉外法律服务大数据平台仍享有下列权利：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （一）继续保存用户留存于涉外法律服务大数据平台的各类信息；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        （二）对于用户过往的违约行为，涉外法律服务大数据平台仍可依法追究违约责任。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">六、知识产权</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        除非涉外法律服务大数据平台另行声明，涉外法律服务大数据平台内的所有产品、技术、软件、程序、数据及其他信息及相关权利等，均归涉外法律服务大数据平台所有。未经涉外法律服务大数据平台许可，任何人不得擅自使用或将其用于商业目的。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >七、用户个人信息的保护</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">7.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p
      style="
        margin: 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 20pt;
        widows: 0;
      "
    >
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >用户同意涉外法律服务大数据平台根据《全国人民代表大会常务委员会关于加强网络信息保护的决定》、工业和信息化部《电信和互联网用户个人信息保护规定》（工业和信息化部第</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >24号令）等有关规定及本协议收集、使用、储存、分享和保护用户的个人信息。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">7.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p
      style="
        margin: 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 20pt;
        widows: 0;
      "
    >
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >涉外法律服务大数据平台依据相关法律、法规合法、正当收集及使用个人信息，并遵循以下原则：</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >　　（一）不以欺骗、误导或者强迫等方式或者违反法律、行政法规以及双方的约定收集、使用信息；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >　　（二）不收集提供服务所必需以外的用户个人信息或者将信息使用于其提供服务之外的目的；</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >　　（三）对在提供服务过程中收集、使用的用户个人信息严格保密，不泄露、篡改或者毁损，不出售或者非法向他人提供，司法机关或行政机关基于法定程序要求涉外法律服务大数据平台提供的情况除外。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">7.3</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p
      style="
        margin: 0pt;
        orphans: 0;
        text-align: justify;
        text-indent: 20pt;
        widows: 0;
      "
    >
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >保护用户个人信息是涉外法律服务大数据平台的一项基本政策，涉外法律服务大数据平台保证不对外公开或向第三方提供用户的注册资料及用户在使用涉外法律服务大数据平台服务时存储在涉外法律服务大数据平台的非公开内容，但法律法规另有规定的除外</span><span style="font-family: 等线; font-size: 10.5pt">。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">八、其他</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">8.1</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp;
        本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律法规。若用户和涉外法律服务大数据平台之间发生争议或纠纷，可以协商解决；协商不成，将争议或纠纷提交</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >上合管委会所在地有管辖权的人民法院处理</span><span style="font-family: 等线; font-size: 10.5pt">。</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">8.2</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span style="font-family: 等线; font-size: 10.5pt">&nbsp;</span>
    </p>
    <p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0">
      <span
        style="font-family: 等线; font-size: 10.5pt"
      >&nbsp;&nbsp;&nbsp; 对条款的</span><span style="font-family: 等线; font-size: 10.5pt">变更、</span><span
        style="font-family: 等线; font-size: 10.5pt"
      >解释权及对本系统使用的解释权归</span><span style="font-family: 等线; font-size: 10.5pt">上合管委会</span><span style="font-family: 等线; font-size: 10.5pt">所有。</span>
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.content {
  p {
    padding-top: 6px;
    line-height: 14pt;
  }
  // p:first-child, p:first-child>span {
  //   font-size: 20px !important;
  //   text-align: center !important;
  //   font-weight: bold;
  // }
}
</style>

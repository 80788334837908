<template>
  <el-dialog
    title="协议内容"
    :visible="visible"
    width="700px"
    @close="$emit('update:visible', false)"
  >
    <div class="container">
      <zh v-if="lang == 'zh'" />
      <ru v-else-if="lang == 'ru'" />
      <en v-else-if="lang == 'en'" />
    </div>
  </el-dialog>
</template>

<script>
import ru from './ru'
import en from './en'
import zh from './zh'
export default {
  name: 'Agreement',
  components: {
    zh,
    ru,
    en,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lang() {
      let result = this.$i18n.locale
      if (this.$route.query.abroad === 'abroad') {
        result = 'en'
      }
      return result
    },
  },
}
</script>

<style scoped>
.container {
  height: 600px;
  overflow-y: scroll;
}
</style>
